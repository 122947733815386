import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import FuelSystemLinesDetailTab from "./FuelSystemLinesDetailTab";
import FuelSystemLinesReadings from "./FuelSystemLinesReadings";
import { AnalyticsService } from "../../services/analytics.service.ts";
import { useErrorHandler } from "../../services/errorhandler";
import { useSelector } from "react-redux";
import { SiteDDLService } from "../../services/sitesDdl.service.ts";
const FuelSystemLines = () => {
  const errorHandler = useErrorHandler();
  const updatename = useSelector(state => state?.auth?.organization)

  const [sites, setSites] = useState([]);
  const [isSiteddlData, setISSiteddlData] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    dates: [
      new Date(new Date().setDate(new Date().getDate() - 30)), // 30 days back
      new Date(),
    ],
    sites: []
  })
  useEffect(() => {
    getSiteDetails();
  }, [])
  const getSiteDetails = () => {
    SiteDDLService.getSitesDDL()
      .pipe(errorHandler("Get Sites"))
      .subscribe((response) => {
        if (response?.data) {
          // setSiteList(response.data)
          
          const facilityOptions = response?.data.map((facility) => ({
            label: facility.facility_name,
            value: facility._id,
            //state: facility.state,
          }));
          setSites(facilityOptions);
          setSelectedFilter((prev) => {
            const updatedFilters = { ...prev };
            updatedFilters["sites"] = [facilityOptions[0].value]
            return updatedFilters
          })
          setISSiteddlData(true)
        }
      });
  };
  const updateGlobalFilters = (name, value) => {
    setSelectedFilter((prev) => {
      const updatedFilters = { ...prev };
      updatedFilters[name] = value
      return updatedFilters
    })
  }
  return (
    <div className="card h-100 w-80 d-flex rounded p-2 flex-grow-1 section_bg">
      <TabView className="FuelSystemLinesTabView flex-grow-1">
        <TabPanel header="Fuel System Readings">
          <FuelSystemLinesReadings getSiteData={isSiteddlData} sites={sites} filerData={selectedFilter} updateGlobalFilters={updateGlobalFilters} />
        </TabPanel>
        <TabPanel header="Fuel System Line Details">
          <FuelSystemLinesDetailTab  sites={sites} filerData={selectedFilter} updateGlobalFilters={updateGlobalFilters} />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default FuelSystemLines;
