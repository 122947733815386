import { configureStore } from "@reduxjs/toolkit";
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from "react-redux";
import { combineReducers } from "redux";
import authReducer from "./slices/auth";
import loaderSliceReducer from "./slices/loaderSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderSliceReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [OrderSlice.actions.updateOrderStopProductAsset], 
      // }
      serializableCheck:false
    }),
});

const { dispatch } = store;
const useSelector = useAppSelector;
const useDispatch = () => useAppDispatch();

export { store, dispatch, useSelector, useDispatch };
