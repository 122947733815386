const reading = [{
    id: 123,
    tankNames: "Regular 87",
    startDate: "2024-06-01",
    endDate: "2024-06-01",
    startFuelVolume: "50000",
    endFuelVolume: "7003",
    dailySales: "600",
    dailyDeliverables: "200",
    bookVolume: "300",
    dailyVariance: "-200",
    cumulativeVariance: "-200"
},
{
    id: 124,
    tankNames: "Regular 87",
    startDate: "2024-06-01",
    endDate: "2024-06-01",
    startFuelVolume: "50000",
    endFuelVolume: "7003",
    dailySales: "600",
    dailyDeliverables: "200",
    bookVolume: "300",
    dailyVariance: "-200",
    cumulativeVariance: "-200"
},
{
    id: 129,
    tankNames: "Regular 87",
    startDate: "2024-06-01",
    endDate: "2024-06-01",
    startFuelVolume: "50000",
    endFuelVolume: "7003",
    dailySales: "600",
    dailyDeliverables: "200",
    bookVolume: "300",
    dailyVariance: "-200",
    cumulativeVariance: "-200"
},
{
    id: 125,
    tankNames: "Regular 87",
    startDate: "2024-06-01",
    endDate: "2024-06-01",
    startFuelVolume: "50000",
    endFuelVolume: "7003",
    dailySales: "600",
    dailyDeliverables: "200",
    bookVolume: "300",
    dailyVariance: "-200",
    cumulativeVariance: "-200"
},
{
    id: 126,
    tankNames: "Regular 87",
    startDate: "2024-06-01",
    endDate: "2024-06-01",
    startFuelVolume: "50000",
    endFuelVolume: "7003",
    dailySales: "600",
    dailyDeliverables: "200",
    bookVolume: "300",
    dailyVariance: "-200",
    cumulativeVariance: "-200"
},
{
    id: 127,
    tankNames: "Regular 87",
    startDate: "2024-06-01",
    endDate: "2024-06-01",
    startFuelVolume: "50000",
    endFuelVolume: "7003",
    dailySales: "600",
    dailyDeliverables: "200",
    bookVolume: "300",
    dailyVariance: "-200",
    cumulativeVariance: "-200"
}]

export const fuel = [{
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading 
}, {
    clientName: "Cody's 0",
    id: 124,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's 1",
    id: 125,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's rt",
    id: 126,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 127,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 128,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 129,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 130,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 131,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 132,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 133,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 134,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 135,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}, {
    clientName: "Cody's",
    id: 123,
    siteName: "Cody's - 1",
    fuelSystemName: "PUL 91",
    totalVolume: "5000",
    periodStart: "2024-06-01",
    periodEnd: "2024-06-01",
    lastReading: "2024-06-01 12:01am",
    nextReading: "2024-06-01 12:01am",
    periodicSales: "3000",
    periodicDeliverables: "1243",
    overUnder: "-200",
    leakCheck: "passed",
    reading
}]


export const siteList = [
    { name: "Cody's - 1", code: '1' },
    { name: "Cody's - 2", code: '2' },
    { name: "Cody's - 3", code: '3' },
    { name: "Cody's - 4", code: '4' },
    { name: "Cody's - 5", code: '5' },
    { name: "Cody's - 6", code: '6' },
    { name: "Cody's - 7", code: '7' },
    { name: "Cody's - 8", code: '8' },
    { name: "Cody's - 9", code: '9' },
    { name: "Cody's - 10", code: '10' }
];

export const productList = [
    { name: "product 1", code: '1' },
    { name: "product 2", code: '2' },
    { name: "product 3", code: '3' },
    { name: "product 4", code: '4' },
    { name: "product 5", code: '5' },
    { name: "product 6", code: '6' },
    { name: "product 7", code: '7' },
    { name: "product 8", code: '8' },
    { name: "product 9", code: '9' },
    { name: "product 10", code: '10' }
];


export const statesList = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Texas', code: 'TX' },
    { name: 'Paris', code: 'PRS' }
];

export const statusList = [
    { name: 'All', code: '' },
    { name: 'Pass', code: 'NY' },
    { name: 'Fail', code: 'RM' },
    // { name: 'Completed', code: 'LDN' },
]; 