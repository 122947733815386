import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
// import {ISite} from "../interface/Site/site"
import {ISite} from "../interface/Site/site"
import { ANALYTICS_URL,STATE_DDL_URLS,CLIENT_DDL_URLS } from "./apiUrls.ts";

export const SitesService = {
    getSiteName: (page: number, pageSize: number,filtervalue: string | null): Observable<any> => {
        let url = `${ANALYTICS_URL.SITES}?limit=${page}&offset=${pageSize}`;
        if (filtervalue) {
            url = url + `&sort_by=${filtervalue}`;
          }
    
        return new Observable<any>((observer) => {
            ApiService.call(url, "GET", "").subscribe({
                next(response: any) {
                    observer.next(response);
                    observer.complete();
                },
                error(err) {
                    observer.error(err);
                    observer.complete();
                },
            })
        })
    },
    getAssetsList: async (page: number, pageSize: number,  filtervalue: string | null) => {
        let url = `${ANALYTICS_URL.SITES}?page=${page}&page_size=${pageSize}`;
  
        // if (searchValue) {
        //   url = url + `&search=${searchValue}`;
        // }
        if (filtervalue) {
          url = url + `&ordering=${filtervalue}`;
        }
  
        return new Observable<any>((observer) => {
            ApiService.call(url, "GET", "").subscribe({
                next(response: any) {
                    observer.next(response);
                    observer.complete();
                },
                error(err) {
                    observer.error(err);
                    observer.complete();
                },
            })
        })
      },
    getSiteById: (req: any): Observable<any> => {
        const _body = JSON.stringify(req)
        let url = `${ANALYTICS_URL.SITES}/${req.id}`;
        return new Observable<any>((observer) => {
            ApiService.call(url, "GET", "").subscribe({
                next: (response: any) => {
                    observer.next(response);
                    observer.complete();
                },
                error: (error) => {
                    observer.error(error);
                    observer.complete();
                },
            })
        })
    },
    createSiteRecord(req: ISite): Observable<string> {
        const body = JSON.stringify(req);
        return new Observable<string>((observer) => {
            ApiService.call(ANALYTICS_URL.SITES, "POST", body).subscribe({
                next: (response: any) => {
                    observer.next(response);
                    observer.complete();
                },
                error: (error) => {
                    observer.error(error);
                    observer.complete();
                },
            })
        })
    },
    updateSiteRecord(req: any,editId:any): Observable<string> {
        const body = JSON.stringify(req);
        let url = `${ANALYTICS_URL.SITES}/${editId}`
        return new Observable<string>((observer) => {
            ApiService.call(url, "PUT", body).subscribe({
                next: (response: any) => {
                    observer.next(response);
                    observer.complete();
                },
                error: (error) => {
                    observer.error(error);
                    observer.complete();
                },
            })
        })
    },
    getStates: (): Observable<any> => {
        return new Observable<any>((observer) => {
          let url = `${STATE_DDL_URLS.STATE}`;
          ApiService.call(url, "GET","").subscribe({
            next: (response: any) => {
              observer.next(response);
              observer.complete();
            },
            error: (error) => {
              observer.error(error);
              observer.complete();
            },
          });
        });
      },
      getClientName: (): Observable<any> => {
        return new Observable<any>((observer) => {
            ApiService.call(CLIENT_DDL_URLS.CLIENT, "GET", "").subscribe({
                next(response: any) {
                    observer.next(response);
                    observer.complete();
                },
                error(err) {
                    observer.error(err);
                    observer.complete();
                },
            })
        })
    },
}