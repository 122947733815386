import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { GETSITESDDL_URL} from "./apiUrls.ts";
export const SiteDDLService = {
  getSitesDDL: (): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(GETSITESDDL_URL.GET_SITESDDL, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  }
}