import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import "./FuelSystemLines.css";
import { Dropdown } from 'primereact/dropdown';

const CalculateVarianceDialog = ({ sites, onCancel, onCalculate ,Date}) => {
    const initialValues = {
        start_date: Date?.dates[0],
        end_date:Date?.dates[1],
        site_id: "",
    };
    const validationSchema = Yup.object({
        start_date: Yup.string().required('Start date is required'),
        end_date: Yup.string().required('End date is required'),
        site_id: Yup.string().required('Site Name is required'),
    });

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        onCalculate(values);
    };

    const FormikCalendar = ({ field, form, ...props }) => {
        return (
            <Calendar
                id={field.name}
                value={field.value}
                onChange={(e) => form.setFieldValue(field.name, e.value)}
                onBlur={() => form.setFieldTouched(field.name)}
                {...props}
            />
        );
    };
    const FormikDropdown = ({ field, form, options }) => {
        return (
            <Dropdown
                id={field.name}
                value={field.value}
                loading={!options?.length}
                options={options}
                onChange={(e) => form.setFieldValue(field.name, e?.value)}
                onBlur={() => form.setFieldTouched(field.name)}
                placeholder={"Select Sites"}
                optionLabel="label"
                style={{width: "15rem"}}
                filter={true}
                filterInputAutoFocus={true}
            />
        );
      };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="fuel-lines-form container py-4 px-5">
                    <Field type="hidden" name="_id" />
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="start_date">Start date</label>
                        <div>
                            <Field
                                name="start_date"
                                component={FormikCalendar}
                                dateFormat="mm/dd/yy"
                                placeholder="Select Date"
                            />
                            <ErrorMessage name="start_date" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="end_date">End date</label>
                        <div>
                            <Field
                                name="end_date"
                                component={FormikCalendar}
                                dateFormat="mm/dd/yy"
                                placeholder="Select Date"
                            />
                            <ErrorMessage name="end_date" component="div" className='form-error' />
                        </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="site_id">Site Name</label>
                        <div>
                            <Field
                                name="site_id"
                                component={FormikDropdown}
                                options={sites}
                            />
                            <ErrorMessage name="site_id" component="div" 
                            style={{fontSize: "10px",
                              color: "red",
                              marginTop: "0.2rem",}} />
                        </div>
                    </div>
                    
                    <div className='form-btns d-flex justify-content-end gap-3 pt-3'>
                        <Button type="submit" disabled={isSubmitting}>
                            Calculate Variance
                        </Button>
                        <Button type="button" severity="danger" onClick={onCancel}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default CalculateVarianceDialog