import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import "./FuelSystemReadingsImport.css";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import FuelSystemLinesForm from "./FuelSystemLinesForm";
import { Dialog } from "primereact/dialog";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { FuelMappingService } from "../../services/fuelSystem.service.ts";
import { slice } from "../../store/slices/auth.js";
import { useCommonService } from "../../services/useCommonService.ts";
import FuelSystemReadingsImport from "./FuelSystemReadingsImport.js";
import CalculateVarianceDialog from "./CalculateVarianceDialog.js";
import { Tooltip } from 'primereact/tooltip';
import { format } from "date-fns";
import { Dropdown } from "primereact/dropdown";

const rowsPerPageOptions = [10, 25, 50, 100, 200]
const FuelSystemLinesReadings = (props) => {
  const { sites, filerData: filters, updateGlobalFilters: setGlobalFilters, getSiteData } = props
  const dispatch = useDispatch();
  const commonService = useCommonService();
  const { isLoading } = useSelector((state) => state?.loader || false);
  const updatename = useSelector(state => state?.auth?.organization)
  const { profile } = useSelector((state) => state.auth);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [formData, setFormData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [fuelMapping, setFuelMapping] = useState({ fuelList: [], totalRecords: 0 });
  //const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [filteredData, setFilteredData] = useState(fuelMapping);
  // const [filters, setFilters] = useState({
  //   dates: [
  //     new Date(new Date().setDate(new Date().getDate() - 90)), // 90 days back
  //     new Date(),
  //   ],
  // });
  const [statusValue, setStatuesValue] = useState("All");
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 250);
  const [importDialog, setImportDialog] = useState(false);
  const [calculateVarianceDialog, setCalculateVarianceDialog] = useState(false);
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 50,
    page: 0,
  });
  const formatDatePickerDate = (date) => {
    if (!date) return null;
    const finaldate = format(date, 'yyyy-MM-dd');
    return finaldate;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMaxWidth(window.innerWidth - 250);
      } else {
        setMaxWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (getSiteData) {
      getFuelMapping();
    }
  }, [lazyState, getSiteData]);


  const getFuelMapping = () => {
    const payload = {
      client_id: updatename?.client_id,
      site_id: filters?.sites,
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
    };
    commonService.startLoading();
    RolesService.getFuelStystemLinesList(lazyState.page + 1, lazyState.rows, payload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setFuelMapping({ fuelList: data, totalRecords: data?.length });
          setFilteredData({ fuelList: data, totalRecords: data?.length });
        }
        commonService.stopLoading();
      });
  };

  const getNameByCode = (code) => {
    const site = sites?.find((site) => site.value === code);
    return site ? site.label : code;
  };

  const createNewReading = (data) => {
    const finalValue = { "readings": data }
    commonService.startLoading();
    FuelMappingService.createNew(finalValue)
      .pipe(errorHandler("Creating new reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "new reading created Successfully",
            })
          );
          setFormData(null)
          refreshTableAlongWithFilter();
          commonService.stopLoading();
        }
      });
  };
  const callDeleteForArray = (data) => {
    const payload = {
      inventory_variance_id: data,
    };
    commonService.startLoading();
    FuelMappingService.delete(payload)
      .pipe(errorHandler("Delete Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Deleted Successfully",
            })
          );
          refreshTableAlongWithFilter();
          commonService.stopLoading();
        }
      });
  };
  const handleReadingDeleteClick = (rowData) => {
    callDeleteForArray([rowData?._id]);
  };
  const handleBulkDeleteBySite = (selectedReadingsOfSite) => {
    const ids = selectedReadingsOfSite.map((item) => item._id);
    ids?.length && callDeleteForArray(ids);
  };
  const onReadingEditComplete = (data) => {
    const payload =
    {
      _id: data?.data?._id,
      beginning_inventory_volume: data?.data?.beginning_inventory_volume,
      actual_ending_inventory_volume:
        data?.data?.actual_ending_inventory_volume,
      sales: data?.data?.sales,
      deliveries: data?.data?.deliveries,
    }


    FuelMappingService.fuelsystemupdate(data?.data?._id, payload)
      .pipe(errorHandler("Update Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Updated Successfully",
            })
          );
          refreshTableAlongWithFilter();
        }
      });
  };

  const handleValueChange = (e, field, rowIndex, data, parentid) => {

    const updatedChildData = { ...data[rowIndex] }
    if (field == 'beginning_inventory_volume') {
      updatedChildData.beginning_inventory_volume = e.value
    }
    if (field == 'sales') {
      updatedChildData.sales = e.value
    }
    if (field == 'deliveries') {
      updatedChildData.deliveries = e.value
    }
    if (field == 'actual_ending_inventory_volume') {
      updatedChildData.actual_ending_inventory_volume = e.value
    }
   
    updatedChildData.calculated_ending_inventory = updatedChildData?.beginning_inventory_volume+updatedChildData?.deliveries-updatedChildData?.sales;
    updatedChildData.ending_inventory_difference = updatedChildData?.actual_ending_inventory_volume -updatedChildData.calculated_ending_inventory
    setFuelMapping(prev => {
      const updateFuelList = [...prev.fuelList]
      const parentindex = updateFuelList.findIndex(row => row.parent.site_id == parentid)
      const parentRow = updateFuelList[parentindex]
      parentRow.parent.child[rowIndex] = updatedChildData

      return { ...prev, fuelList: updateFuelList }
    })
    // const parentRow = e.currentTarget.target.closest('tr');

   
    // const bookValueTd = parentRow.querySelector('td.BookValue');

    // If such a td exists, append the value to it.
    // if (bookValueTd) {
    //     bookValueTd.textContent = e.value;
    // } else {
    //     console.log('No td with class "BookValue" found.');
    // }

  }

  const numberEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
      />
    );
  };
  const actionColumn = (rowData, columnProps) => {
    console.log(columnProps)
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps?.rowEditor?.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps?.rowEditor?.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-secondary"
          onClick={columnProps.rowEditor.onInitClick}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={() => handleReadingDeleteClick(rowData)}
        />
      </>
    );
  };

  const exportCSV = () => {
    const csvContent = [];
    fuelMapping?.fuelList?.forEach((parentRow) => {
      csvContent.push(["Site Name"]);
      csvContent.push([getNameByCode(parentRow?.parent.site_id)]);
      csvContent.push([
        "",
        "Tank Number",
        "Product",
        "Date",
        "Beginning Inventory Volume",
        "Actual Ending Inventory Volume",
        "Sales",
        "Deliveries",
        "Book Value",
        "Variance"
      ]);

      parentRow?.parent?.child?.forEach((childRow) => {
        csvContent.push([
          "",
          childRow?.tank_number,
          childRow.product,
          formatDate(childRow.date),
          childRow?.beginning_inventory_volume,
          childRow?.actual_ending_inventory_volume,
          formatNumber(childRow.sales),
          formatNumber(childRow.deliveries),
          formatNumber(childRow?.calculated_ending_inventory),
          formatNumber(childRow?.ending_inventory_difference)
        ]);
      });
      csvContent.push([]);
    });

    const csvString = csvContent.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fuel-system-lines.csv";
    link.click();
  };

  const headerElement = (
    <div className="fw-semibold mx-4 mt-3">{`Create Fuel System Lines`}</div>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString).toISOString();
    const datestr = date.split('T')[0]
    return datestr;
  };

  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };
  const GetFilteredByParameters = (value) => {
    let filteredData = [...value.fuelList];

    if (filters?.sites?.length) {
      filteredData = filteredData.filter((item) =>
        filters?.sites.includes(item?.parent?.site_id)
      );
    }
    if (filters?.sites?.length) {
      filteredData = filteredData.filter((item) =>
        selectedStates.includes(item?.parent?.state)
      );
    }
    if (statusValue) {
      if (statusValue != "All") {
        filteredData = filteredData.filter(
          (item) =>
            statusValue?.toLowerCase() === item?.parent?.result?.toLowerCase()
        );
      }
    }
    return filteredData;
  };
  const onFilter = () => {
    // debugger
    // if (
    //   filters.dates?.length !== 2 &&
    //   !filters?.sites?.length &&
    //   !selectedStates?.length &&
    //   !statusValue
    // ) {
    //   setFilteredData({ fuelList: fuelMapping, totalRecords: fuelMapping?.count });
    //   return;
    // }
    //if (filters?.dates && filters?.dates.length === 2) {
    const payload = {
      client_id: sessionStorage.getItem("client_id"),//profile.client,
      site_id: filters?.sites,
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]) : formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[1]) : formatDatePickerDate(new Date()),
    };
    commonService.startLoading();
    RolesService.getFuelLineReadings(payload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setFuelMapping({ fuelList: data, totalRecords: data.length });
          const newData = (data);
          setFilteredData(newData);
        }
        commonService.stopLoading();
      });
    //return;
    // } else {
    //   const newData = GetFilteredByParameters(fuelMapping);
    //   setFilteredData(newData);
    // }
  };
  const refreshTableAlongWithFilter = () => {
    let myPayload = {
      client_id: sessionStorage.getItem('client_id'),
      site_id: filters?.sites,
    };
    if (filters?.dates && filters?.dates.length === 2) {
      myPayload = {
        client_id: sessionStorage.getItem('client_id'),
        site_id: filters?.sites,
        start_date: formatDatePickerDate(filters?.dates[0]),
        end_date: formatDatePickerDate(filters?.dates[1]),
      };
    }
    commonService.startLoading();
    RolesService.getFuelLineReadings(myPayload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setFuelMapping({ fuelList: data, totalRecords: data?.count });
          const newData = (data);
          // setFuelMapping({});
        }
        commonService.stopLoading();
      });
  };
  const handlePageChange = (e) => {
    setLazyState(e);
  };
  const rowExpansionTemplate = (data) => {
    // debugger
    return (
      <div>
        <div className="d-flex flex-wrap justify-content-end gap-2">
          <Button className="new-reading" label="New Reading" text onClick={() => setFormData({ client_id: sessionStorage.getItem('client_id'), site_id: data?.parent?.site_id })} />
          <Button
            label="Delete"
            text
            className="delete-button"
            disabled={
              !selectedProducts[data.id]?.length ||
              selectedProducts[data.id]?.length < 2
            }
            onClick={() => handleBulkDeleteBySite(selectedProducts[data.id])}
          />
        </div>
        <DataTable
          selectionMode={"checkbox"}
          value={data?.parent?.child || []}
          id="fuel-lines-grid"
          dataKey={data._id}
          selection={selectedProducts[data.id]}
          showSelectAll={false}
          editMode="row"
          onRowEditComplete={onReadingEditComplete}
          scrollable
          scrollHeight={"250px"}
          onSelectionChange={(e) => setSelectedProducts({ ...selectedProducts, [data.id]: e.value })}
          className="child-fuel-grid rounded border flex-grow-1">
          <Column selectionMode="multiple" headerStyle={{ width: "2rem" }} header={"Selected"} />
          <Column field="tank_number" header="Tank Number" />
          <Column field="product" header="Product" />
          <Column field="date" header="Date" body={(rowData) => formatDate(rowData?.date)} />
          <Column field="beginning_inventory_volume" header="Beginning Inventory Volume"
            editor={(props) => (
              <InputNumber
                value={props.rowData.beginning_inventory_volume}
                onValueChange={(e) => handleValueChange(e, 'beginning_inventory_volume', props.rowIndex, data?.parent?.child, data.parent.site_id)} 
              />
            )}
          />
          <Column field="actual_ending_inventory_volume" header="Actual Ending Inventory Volume" 
           editor={(props) => (
            <InputNumber
              value={props.rowData.actual_ending_inventory_volume}
              onValueChange={(e) => handleValueChange(e, 'actual_ending_inventory_volume', props.rowIndex, data?.parent?.child, data.parent.site_id)} 
            />
          )} />
          <Column field="sales" header="Sales" body={(rowData) => formatNumber(rowData?.sales)}

            editor={(props) => (
              <InputNumber
                value={props.rowData.sales}
                onValueChange={(e) => handleValueChange(e, 'sales', props.rowIndex, data?.parent?.child, data.parent.site_id)} 
              />
            )}
          />
          <Column field="deliveries" header="Deliveries" body={(rowData) => formatNumber(rowData?.deliveries)} 
           editor={(props) => (
            <InputNumber
              value={props.rowData.deliveries}
              onValueChange={(e) => handleValueChange(e, 'deliveries', props.rowIndex, data?.parent?.child, data.parent.site_id)} 
            />
          )}/>
          <Column field="calculated_ending_inventory" header="Book Value" className="BookValue" body={(rowData) => formatNumber(rowData?.calculated_ending_inventory)} />
          <Column field="ending_inventory_difference" header="Variance" body={(rowData) => formatNumber(rowData?.ending_inventory_difference)} />
          <Column rowEditor header={"Actions"} align={"center"} body={actionColumn} />
        </DataTable>
      </div>
    );
  };
  const onRowToggle = (e) => {
    if (expandedRow && expandedRow.id === e.data.site_id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
    setSelectedProducts([]);
  };
  const allowExpansion = (rowData) => {
    return rowData?.parent?.child?.length > 0;
  };
  const siteNameBody = (rowData) => {
    const siteName = (rowData?.parent?.site_name);
    return <div>{siteName}</div>;
  };
  const handleImport = (values) => {
    commonService.startLoading();
    const formData = new FormData();
    formData.append("fuel_reading_file", values.file);
    formData.append("site_id", values.site_id);
    formData.append("client_id", sessionStorage.getItem("client_id"));
    FuelMappingService.importFuelSystemLineReading(formData).pipe(errorHandler("Import Fuel Mapping")).subscribe((response) => {
      if (response?.data) {
        // getFuelMapping();
        setImportDialog(false);
        dispatch(
          slice.actions.updateToast({
            severity: "success",
            message: "Fuel system Readings imported.",
          })
        );
        commonService.stopLoading()
      }
    })
    // FuelMappingService.createNew(formData)
    //   .pipe(errorHandler("Import Fuel Mapping"))
    //   .subscribe((response) => {
    //     if (response?.data) {
    //       dispatch(
    //         slice.actions.updateToast({
    //           severity: "success",
    //           message: response.data?.message || "Imported Fuel Mapping",
    //         })
    //       );
    //       setImportDialog(false)
    //       refreshTableAlongWithFilter();
    //       commonService.stopLoading();
    //     }
    //   });
  };
  const handleCalculateVarianceClick = (values) => {
    commonService.startLoading();
    const payload = {
      client_id: sessionStorage.getItem("client_id"),//profile.client,
      site_id: [values.site_id],
      start_date: formatDatePickerDate(values.start_date),
      end_date: formatDatePickerDate(values.end_date),
    };
    FuelMappingService.calculate(payload).pipe(errorHandler("calculate variance")).subscribe((response) => {
      if (response?.data) {
        getFuelMapping();
        setCalculateVarianceDialog(false);
        dispatch(
          slice.actions.updateToast({
            severity: "success",
            message: response?.data?.message || "variance calculated.",
          })
        );
        commonService.stopLoading()
      }
    })
  };
  const handleRowsPerPageChange = (e) => {
    setLazyState({
      ...lazyState,
      rows: e.value,
    });
  };
  return (
    <div className="d-flex flex-column h-100 p-3 fuel-system-lines-readings-container">
      {/* <div className="fuel-title">Fuel System Lines</div> */}
      <div className="d-flex align-items-end flex-column flex-sm-row">
        <div
          className="d-flex flex-row col-12 col-sm-8 flex-grow-1 flex-wrap gap-3"
          style={{ alignItems: "flex-end" }}
        >
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="site">Site Name</label>
            <MultiSelect
              value={filters?.sites}
              options={sites}
              loading={!sites.length}
              optionLabel="label"
              onChange={(e) => setGlobalFilters('sites', e.value)}  // setSelectedFacilities(e.value)
              placeholder="Select Sites"
              className="w-80"
              filter
              maxSelectedLabels={3}
              display='chip'
              selectAllLabel="Select All"
            />
          </div>

          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <Tooltip target=".custom-target-icon" />
            <label htmlFor="date">Date Range <i className="custom-target-icon pi pi-info-circle p-text-secondary"
              data-pr-tooltip="Default date range is T-30 days"
              data-pr-position="right"
              style={{ fontSize: '0.8rem', cursor: 'pointer' }}>
            </i></label>
            <Calendar
              value={filters?.dates}
              onChange={({ target: { name, value } }) => {
                // setFilters({ ...filters, [name]: value })
                setGlobalFilters(name, value)
              }
              }
              onHide={() =>
                (!filters.dates?.[0] || !filters.dates?.[1]) &&
                setGlobalFilters("dates", null)
                //setFilters({ ...filters, dates: null })
              }
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              placeholder="Select Date"
              id="dates"
              name="dates"
              showButtonBar
            />
          </div>
          <div
            className="d-flex align-items-center flex-column gap-1 col-12 col-md-6 flex-wrap justify-content-end grid_buttonsWObg"
            style={{ height: "47px", alignContent: "flex-end", width: "100%" }}
          >
            <Button label="Filter" className="filter-sign" onClick={onFilter} text>
              <i className="pi pi-filter filter-action-icon"></i>
            </Button>
            <Button label="Import" className="import-sign" text onClick={() => setImportDialog(true)}>
              <i className="pi pi-download import-action-icon"></i>
            </Button>
            <Button label="Export" className="export-sign" text onClick={exportCSV}>
              <i className="pi pi-upload export-action-icon"></i>
            </Button>
            <Button label="Calculate Variance" className="inventory-varience-button" text onClick={() => setCalculateVarianceDialog(true)} />
            <div className="mb-1">
              <span>Show: </span>
              <Dropdown
                value={lazyState.rows}
                options={rowsPerPageOptions}
                onChange={handleRowsPerPageChange}
                className="p-dropdown"
              />
            </div>
          </div>
        </div>
        <div>
          <Dialog
            appendTo="self"
            visible={importDialog}
            modal
            header={
              <div className="fw-semibold mx-4 mt-3">
                {`Import Fuel System Readings`}
              </div>
            }
            onHide={() => setImportDialog(false)}
          >
            <FuelSystemReadingsImport
              sites={sites}
              onCancel={() => setImportDialog(false)}
              onSubmit={handleImport}
            />
          </Dialog>
          <Dialog
            appendTo="self"
            visible={calculateVarianceDialog}
            modal
            header={
              <div className="fw-semibold mx-4 mt-3">
                {`Calculate Variance`}
              </div>
            }
            onHide={() => setCalculateVarianceDialog(false)}
          >
            <CalculateVarianceDialog
              Date={filters}
              sites={sites}
              onCancel={() => setCalculateVarianceDialog(false)}
              onCalculate={handleCalculateVarianceClick}
            />
          </Dialog>
        </div>
      </div>
      <Dialog
        appendTo="self"
        visible={!!formData}
        modal
        header={headerElement}
        onHide={() => setFormData(null)}
      >
        <FuelSystemLinesForm
          editData={formData}
          site={filters?.sites}
          siteName={fuelMapping?.fuelList[0]?.parent?.site_name}
          onSave={(data) => createNewReading(data)}
          onCancel={() => setFormData(null)}
        />
      </Dialog>
      <Toast ref={toast} />

      <div className="w-100 rounded customGrid">
        <DataTable
          id="fuel-lines-details-grid"
          ref={dt}
          value={fuelMapping?.fuelList}
          totalRecords={fuelMapping.totalRecords}
          dataKey="parent.site_id"
          showGridlines={false}
          scrollable
          // scrollHeight={window.innerHeight - 220}
          size="large"
          className="fuel-system-grid rounded border w-100 flex-grow-1"
          paginator
          lazy={true}
          rows={lazyState.rows}
          first={lazyState.first}
          pageLinkSize={10}
          rowsPerPageOptions={rowsPerPageOptions}
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="{first} - {last} of {totalRecords} Records"
          reorderableColumns
          resizableColumns
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={[expandedRow]}
          onRowExpand={onRowToggle}
          onRowCollapse={onRowToggle}
          emptyMessage="No records found."
          onPage={handlePageChange}
        >
          <Column expander={allowExpansion} style={{ width: "3rem" }} />
          <Column body={siteNameBody} field="parent" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} header="Site Name" />
        </DataTable>
      </div>
    </div>
  );
}

export default FuelSystemLinesReadings;