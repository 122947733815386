import axios from "axios";
import { Observable } from "rxjs";
import { Method } from "./types"

const token = localStorage.getItem("token");
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`
    }
})

export default axiosInstance;

export class BaseRequestModel {

    constructor(private url: string, private method: Method, private data?: any, private headers?: any) { }

    request(): Observable<any> {
        return new Observable((observer) => {
            axios(this.url, {
                method: this.method,
                data: this.data,
                headers: this.headers
            }).then(response => {
                observer.next(response)
                observer.complete()
            }).catch(error => {
                if (error?.response?.status === 401 && error?.response?.data?.message?.includes("Invalid token!")) {
                    if (!localStorage.getItem("isRedirecting")) {
                        localStorage.setItem("isRedirecting", "true");  // Flag indicating redirection is in progress
                        localStorage.clear();
                        window.location.replace("/login");
                    }
                    return;
                }
                observer.error(error)
                observer.complete()
            })

            return () => {
                // clean up on unsubscribe
                observer.complete()
            }
        });
    }
}