export enum DASHBOARD_URLS {

}

export enum LOGIN_URLS {
    LOGIN = "login/",
    PROFILE = "profile/",
    REGISTER = "user"
}

export enum FUEL_MAPPING_URLS {
    GET_LIST = "fuel_system_mapping",
    CREATE = "create_fuel_system/",
    CREATENEW = "create_fuel_reading",
    DELETE = "delete_fuel_reading/",
    CALCULATE = "calculate_variance",
    // UPDATE = "update_fuel_system/",
    UPDATE = "fuel_system_mapping",
    IMPORT = "upload_fuel_systems/",
    GROUP = 'iv_group_report',
    GET_SYSTEM_LINEREADING_LIST = 'fuel_reading',
    GET_FUEL_SYSTEM_MINIMAL="ddl_fuel_system_mapping/",
    GET_INVENTORY_MINIMAL="get_beginning_inventory_volume",
    GET_TANK_MINIMAL="ddl_fuel_system_tanks",
    GET_MINIMAL_SITES = "ddl_site_name",
    EXPORT_SYSTEMLINES_DATA = 'iv_group_export_report',
}
export enum FUEL_SYSTEM_URLS {
    UPDATE = "fuel_reading/",
    IMPORT_FUEL_SYSTEM_LINE_READING = "upload_fuel_readings/",
}

export enum ANALYTICS_URL {
    SITES = "site",
    GET_SITES = "search_site_name",
    GET_STATES = "search_state/",
}
export enum STATE_DDL_URLS {
    STATE = "state/"
}
export enum CLIENT_DDL_URLS {
    CLIENT = "client/"
}


export enum REPORTING_URL {
    GET_EXPORT = "export_report",//"generator_variance/",
    // GET_STATES = "search_state/"
}
export enum PRODUCT_URLS {
    GET_LIST = "product_category_sites",
    GET_PRODUCT_MINIMAL = "ddl_product",
    CREATE = "product",
    UPDATE = "product/",
    SALES = "search_date_range/",
    CUMULATIVESALES = "cummilative_sum/",
    GET_PRODUCT_CATEGORY = "ddl_product"
}


export enum ROLES_URLS {
    GET_USERS = "user",
    GET_PERMISSIONS = "permission",
    GET_ROLE_PERMISSIONS = "roles",
    UPDATE_USER = "user",
    CREATE_PERMISSION = "permission",
    UPDATE_PERMISSION = "permission",
    CREATE_ROLE = "roles",
    UPDATE_ROLE = "roles/",
    Group = 'iv_group_report/'
}

export enum USERPROFILE_URL {
    GET_USERSPROFILE = "profile",
}

export enum GETSITESDDL_URL {
    GET_SITESDDL = "ddl_site_name",
}

