import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { FUEL_MAPPING_URLS, FUEL_SYSTEM_URLS } from "./apiUrls.ts";

export const FuelMappingService = {
  getFuelMapping: (page: number, pageSize: number,sortType:string): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GET_LIST}?limit=${page}&offset=${pageSize}&sort_by=${sortType}`;

      ApiService.call(url, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },

  create: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.CREATE, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  createNew: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.CREATENEW, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  delete: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.DELETE, "DELETE", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  updateFuelSystemMapping: (id: number, body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_MAPPING_URLS.UPDATE}/${id}`;
      ApiService.call(url, "PUT", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  fuelSystemMappingCreate: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_MAPPING_URLS.UPDATE}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  fuelsystemupdate: (id: number, body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_SYSTEM_URLS.UPDATE}${id}`;
      ApiService.call(url, "PUT", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  calculate: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${FUEL_MAPPING_URLS.CALCULATE}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  import: (formdata: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.IMPORT, "POST", formdata, {
        'Content-Type': 'multipart/form-data',
      }).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  exportFuelLinesData: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.EXPORT_SYSTEMLINES_DATA, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  importFuelSystemLineReading: (formdata: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_SYSTEM_URLS.IMPORT_FUEL_SYSTEM_LINE_READING, "POST", formdata, {
        'Content-Type': 'multipart/form-data',
      }).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  }
}
