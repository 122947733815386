import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { PRODUCT_URLS } from "./apiUrls.ts";

export const ProductService = {
  getProducts: (page: number, pageSize: number,sortType:string): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${PRODUCT_URLS.GET_LIST}?limit=${page}&offset=${pageSize}&sort_by=${sortType}`;
      ApiService.call(url, "GET","").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getProductsDropdown: (id:any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${PRODUCT_URLS.GET_PRODUCT_MINIMAL}`;
      ApiService.call(url, "POST",id).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  create: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(PRODUCT_URLS.GET_LIST, "POST", body).subscribe({  // PRODUCT_URLS.CREATE
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  update: (id:number,body: any): Observable<any> => {
    let url = `${PRODUCT_URLS.GET_LIST}/${id}`;  // UPDATE
    return new Observable<any>((observer) => {
      ApiService.call(url, "PATCH", body).subscribe({  // "PUT"
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
}