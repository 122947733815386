import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Column } from "primereact/column";
import "./FuelSystemLines.css";
import { statusList } from "./data";
import { format } from 'date-fns'
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import FuelSystemLinesForm from "./FuelSystemLinesForm";
import { Dialog } from "primereact/dialog";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { FuelMappingService } from "../../services/fuelSystem.service.ts";
import { slice } from "../../store/slices/auth.js";
import { useCommonService } from "../../services/useCommonService.ts";
import { Tooltip } from 'primereact/tooltip';
import { SitesService } from "../../services/site.service.ts";

const rowsPerPageOptions = [25, 50, 100, 200]

const FuelSystemLinesDetailTab = (props) => {
  const { sites, filerData: filters, updateGlobalFilters: setGlobalFilters, siteList } = props
  const dispatch = useDispatch();
  const commonService = useCommonService();
  const { isLoading } = useSelector((state) => state?.loader || false);
  // const { profile } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const errorHandler = useErrorHandler();
  const [fuelMapping, setFuelMapping] = useState({ fuelList: [], totalRecords: 0 });
  const [selectedStates, setSelectedStates] = useState([]);
  const [filteredData, setFilteredData] = useState(fuelMapping);
  
  const [statusValue, setStatuesValue] = useState("All");
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 250);
  const [stateOptions, setStateOptions] = useState([]);
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 50,
    page: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMaxWidth(window.innerWidth - 250);
      } else {
        setMaxWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getFuelMapping();
    getStateDatails();
  }, [lazyState]);

  const getStateDatails = () => {
    commonService.startLoading()
    SitesService.getStates().pipe(errorHandler("Get State")).subscribe((res) => {
      if (res?.data) {
        setStateOptions(res.data);
        commonService.stopLoading();
      }
    })
  };
  const formatDatePickerDate = (dateString) => {
    if(!dateString) return null;
    const date =  format(dateString, 'yyyy-MM-dd');
    return date;
  };

  const getFuelMapping = () => {
    const payload = {
      client_id: sessionStorage.getItem("client_id"),// profile.client,
      site_id: filters?.sites,
      start_date: (filters.dates) ? formatDatePickerDate(filters?.dates?.[0]): formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))),
      end_date: (filters.dates)?formatDatePickerDate(filters?.dates?.[1]):formatDatePickerDate(new Date()),
      status: statusValue
    };
    commonService.startLoading();
    RolesService.getFuelLineData(lazyState.page + 1, lazyState.rows, payload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = (response?.data);
          setFuelMapping({ fuelList: data.results, totalRecords: data?.count });
          setFilteredData(data.results);
        }
        commonService.stopLoading();
      });
  };
  const createNewReading = (data) => {
    // const payload = {
    //   site_id: profile?.sites,
    //   client_id: profile?.client,
    // };
  };
  const callDeleteForArray = (data) => {
    const payload = {
      inventory_variance_id: data,
    };

    FuelMappingService.delete(payload)
      .pipe(errorHandler("Delete Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Deleted Successfully",
            })
          );
          refreshTableAlongWithFilter();
        }
      });
  };
  const handleReadingDeleteClick = (rowData) => {
    callDeleteForArray([rowData?._id]);
  };
  const handleBulkDeleteBySite = (selectedReadingsOfSite) => {
    const ids = selectedReadingsOfSite.map((item) => item._id);
    ids?.length && callDeleteForArray(ids);
  };
  const onReadingEditComplete = (data) => {
    const payload = [
      {
        _id: data?.newData?._id,
        Beginning_Inventory_Volume: data?.newData?.Beginning_Inventory_Volume,
        Actual_Ending_Inventory_Volume:
          data?.newData?.Actual_Ending_Inventory_Volume,
        Sales: data?.newData?.Sales,
        Deliveries: data?.newData?.Deliveries,
      },
    ];

    FuelMappingService.update(data?.newData?._id, payload)
      .pipe(errorHandler("Update Reading"))
      .subscribe((response) => {
        if (response?.data) {
          dispatch(
            slice.actions.updateToast({
              severity: "success",
              message: response.data?.message || "Updated Successfully",
            })
          );
          refreshTableAlongWithFilter();
        }
      });
  };

  //there is no state in facility
  /*const uniqueStates = [...new Set(sites.map((facility) => facility.state))];
  console.log("uniqueStates", uniqueStates);
  const stateOptions = uniqueStates.map((state) => ({
    label: state,
    value: state,
  }));
  console.log("stateOptions", stateOptions);
  */

  const numberEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
      />
    );
  };
  const actionColumn = (rowData, columnProps) => {
    return columnProps.rowEditor.editing ? (
      <>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text p-button-success"
          onClick={columnProps.rowEditor.onSaveClick}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={columnProps.rowEditor.onCancelClick}
        />
      </>
    ) : (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-secondary"
          onClick={columnProps.rowEditor.onInitClick}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={() => handleReadingDeleteClick(rowData)}
        />
      </>
    );
  };

  function replaceCommasWithSpaces(input) {
    if (typeof input === "string") {
      return input.split(",").join(" ");
    }
    return input;
  }

  //to format the date in the form internationla date system as MM/DD/YYYY
  const InterNationalDateformat = (dateString) => {
    const date = new Date(dateString).toISOString();
    const datestr = date.split('T')[0]
    return datestr;
  }

  //Excel sheet data formating
  const exportCSV = (fileData) => {
    const csvContent = [];
    fuelMapping?.fuelList?.forEach((parentRow) => {
      //Parent headers
      csvContent.push([
        "Client Name",
        "Site Name",
        "State",
        "Fuel System Name",
        "Period Start",
        "Period End",
        "Last Reading",
        "Next Reading",
        "Periodic Sales",
        "Periodic Deliverables",
        "Over/Under",
        "Leak Check Status",
      ]);

      //Parent data
      csvContent.push([
        parentRow?.parent.client_name,
        parentRow?.parent.facility_name,
        parentRow?.parent.state,
        parentRow?.parent.Fule_System_Name,
        InterNationalDateformat(parentRow?.parent.Period_Start),
        InterNationalDateformat(parentRow?.parent.Period_End),
        InterNationalDateformat(parentRow?.parent.Last_Reading),
        InterNationalDateformat(parentRow?.parent.Next_Reading),
        formatNumber(parentRow?.parent.Periodic_Sales),
        formatNumber(parentRow?.parent.Periodic_Deliveries),
        formatNumber(parentRow?.parent.Over_Under),
        parentRow?.parent.result,
      ]);

      //Child Header
      csvContent.push([
        "",
        "Tank Number",
        "Product",
        "Date",
        "Beginning Inventory Volume",
        "Actual Ending Inventory Volume",
        "Sales",
        "Deliveries",
        "Book Value",
        "Variance",
        "Cumulative Variance",
      ]);

      //Child data
      parentRow.parent.child.forEach((childRow) => {
        csvContent.push([
          "",
          replaceCommasWithSpaces(childRow.tank_number),
          childRow.product,
          (InterNationalDateformat(childRow.only_date)),
          (childRow.beginning_inventory_volume),
          childRow.actual_ending_inventory_volume,
          formatNumber(childRow.sales),
          formatNumber(childRow.deliveries),
          formatNumber(childRow.book_value),
          formatNumber(childRow.variance),
          formatNumber(childRow.cumulative_variance),
        ]);
      });

      //Adding an empty row after one record
      csvContent.push([]);
    });

    const csvString = csvContent.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fuel-system-lines.csv";
    link.click();
  };

  //Export sele ted sites data into an excel sheet
  const exportSitesData = (event) => {
    event.preventDefault();

    const { dates } = filters;
    let startDate = null;
    let endDate = null;

    //checking it date range is selected or not
    if (dates && dates.length > 0) {
      startDate = dates[0];
      endDate = dates[1];
      if (!endDate) {
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 2);
      }
    }

    //formating the date ranges
    const formattedStartDate = formatDate(startDate).replaceAll('/', '-');
    const formattedEndDate = formatDate(endDate).replaceAll('/', '-');

    const payload = {
      client_id: sessionStorage.getItem('client_id'),//profile?.client,
      site_id: filters?.sites, //array
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };

    //start the loader
    commonService.startLoading();
    //Getting export details of the selected list of sites
    FuelMappingService.exportFuelLinesData(payload)
      .pipe(errorHandler("Get Generate Export"))
      .subscribe((response) => {
        exportCSV(response.data);
        //stop the loader
        commonService.stopLoading();
      });
  };

  const headerElement = (
    <div className="fw-semibold mx-4 mt-3">{`Create Fuel System Lines`}</div>
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString).toISOString();
    // const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    // const day = String(date.getUTCDate()).padStart(2, "0");
    // const year = date.getUTCFullYear();
    const datestr = date.split('T')[0]
    return datestr;
  };
  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };
  const GetFilteredByParameters = (value) => {
    let filteredData = [...value];

    if (filters?.sites?.length) {
      filteredData = filteredData.filter((item) =>
        filters?.sites.includes(item?.parent?.site_id)
      );
    }
    if (selectedStates?.length) {
      filteredData = filteredData.filter((item) =>
        selectedStates.includes(item?.parent?.state)
      );
    }
    if (statusValue) {
      if (statusValue !== "All") {
        filteredData = filteredData.filter(
          (item) =>
            statusValue?.toLowerCase() === item?.parent?.result?.toLowerCase()
        );
      }
    }
    return filteredData;
  };
  const onFilter = () => {
    // if (
    //   filters.dates?.length !== 2 &&
    //   !filters?.sites?.length &&
    //   !selectedStates?.length &&
    //   !statusValue
    // ) {
    //   setFilteredData(fuelMapping);
    //   return;
    // }
    // if (filters.dates && filters.dates.length === 2) {
      const payload = {
        client_id: sessionStorage.getItem("client_id"),
        site_id: filters?.sites,
        start_date: (filters.dates) ? formatDatePickerDate(filters.dates[0]).replaceAll('/', '-'): formatDatePickerDate(new Date(new Date().setDate(new Date().getDate() - 30))).replaceAll('/', '-'),
        end_date: (filters.dates)?formatDatePickerDate(filters.dates[1]).replaceAll('/', '-'):formatDatePickerDate(new Date()).replaceAll('/', '-'),
        status: statusValue
      };
      commonService.startLoading();
      RolesService.getFuelLineData(lazyState.page + 1, lazyState.rows,payload)
        .pipe(errorHandler("Get Fuel Mapping"))
        .subscribe((response) => {
          if (response?.data) {
            // const data = response?.data?.results?.map((item) => {
            //   item.id = Math.random().toString(36).substr(2, 9);
            //   return item;
            // });
            const data = (response?.data);
            setFuelMapping({ fuelList: data.results, totalRecords: data.count });
            //const newData = GetFilteredByParameters(data.results);
            //setFilteredData(newData);
          }
          commonService.stopLoading();
        });
    //   return;
    // } else {
    //   const newData = GetFilteredByParameters(fuelMapping);
    //   setFilteredData(newData);
    // }

  };
  const refreshTableAlongWithFilter = () => {
    let myPayload = {
      client_id: sessionStorage.getItem("client_id"),
      site_id: filters?.sites,
    };
    if (filters.dates && filters.dates.length === 2) {
      myPayload = {
        client_id: sessionStorage.getItem("client_id"),
        site_id: filters?.sites,
        start_date: formatDate(filters.dates[0]),
        end_date: formatDate(filters.dates[1]),
      };
    }
    commonService.startLoading()
    RolesService.getFuelLineData(myPayload)
      .pipe(errorHandler("Get Fuel Mapping"))
      .subscribe((response) => {
        if (response?.data) {
          const data = response?.data?.results?.map((item) => {
            item.id = Math.random().toString(36).substr(2, 9);
            return item;
          });
          setFuelMapping(data);
          const newData = GetFilteredByParameters(data);
          setFilteredData(newData);
        }
        commonService.stopLoading()
      });
  };
  const rowExpansionTemplate = (data) => {
    return (
      <div>
        {/* <div className="d-flex flex-wrap justify-content-end grid_buttons gap-2">
          <Button label="New Reading" text onClick={() => setFormData({})} />
          <Button
            label="Delete"
            text
            className="delete-button"
            disabled={
              !selectedProducts[data.id]?.length ||
              selectedProducts[data.id]?.length < 2
            }
            onClick={() => handleBulkDeleteBySite(selectedProducts[data.id])}
          />
        </div> */}
        <DataTable
          value={data.parent.child || []}
          id="fuel-lines-grid"
          dataKey={data._id}
          showSelectAll={false}
          editMode="row"
          // onRowEditComplete={onReadingEditComplete}
          scrollable={true}
          scrollHeight={"250px"}
          // onSelectionChange={(e) =>
          //   setSelectedProducts({ ...selectedProducts, [data.id]: e.value })
          // }
          className="child-fuel-grid rounded border flex-grow-1"
        >

          <Column field="tank_number" header="Tank Number" />
          <Column field="product" header="Product" />
          <Column field="only_date" header="Date" body={(rowData) => formatDate(rowData?.only_date)} />
          <Column
            field="beginning_inventory_volume"
            header="Beginning Inventory Volume"
            editor={numberEditor}
          ></Column>
          <Column
            field="actual_ending_inventory_volume"
            header="Actual Ending Inventory Volume"
            editor={numberEditor}
          ></Column>
          <Column
            field="sales"
            header="Sales"
            body={(rowData) => formatNumber(rowData?.sales)}
            editor={numberEditor}
          ></Column>
          <Column
            field="deliveries"
            header="Deliveries"
            editor={numberEditor}
            body={(rowData) => formatNumber(rowData?.deliveries)}
          ></Column>
          <Column
            field="book_value"
            body={(rowData) => formatNumber(rowData?.book_value)}
            header="Book Value"
          />
          <Column
            field="variance"
            body={(rowData) => formatNumber(rowData?.variance)}
            header="Variance"
          />
          <Column
            field="cumulative_variance"
            body={(rowData) => formatNumber(rowData?.cumulative_variance)}
            header="Cumulative Variance"
          />
        </DataTable>
      </div>
    );
  };
  const onRowToggle = (e) => {
    if (expandedRow && expandedRow.id === e.data.id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(e.data);
    }
  };
  const allowExpansion = (rowData) => {
    return rowData.parent.child.length > 0;
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-multiselect-select-all > .p-checkbox"
    );
    selectAllCheckbox.after(" Select All");
  };

  const handlePageChange = (e) => {
    setLazyState(e);
  };
  const handleRowsPerPageChange = (e) => {
    setLazyState({
      ...lazyState,
      rows: e.value,
    });
  };
  return (
    <div className="d-flex flex-column h-100 p-3 fuel-system-lines-detail-container">
      {/* <div className="fuel-title">Fuel System Lines</div> */}
      <div className="d-flex align-items-end flex-column flex-sm-row">
        <div className="d-flex flex-row col-12 col-sm-8 flex-grow-1 flex-wrap gap-3">
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="site">Site Name</label>
            <MultiSelect
              value={filters?.sites}
              options={sites}
              loading={!sites?.length}
              optionLabel="label"
              onChange={(e) => setGlobalFilters('sites', e.value)} // setSelectedFacilities(e.value)
              placeholder="Select Sites"
              onShow={onShow}
              filter
              maxSelectedLabels={3}
              display='chip'
              selectAllLabel="Select All"
            />
          </div>
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="state">State</label>
            <MultiSelect
              value={selectedStates}
              options={stateOptions}
              optionValue="name"
              optionLabel="name"
              onChange={({ target: { value } }) => setSelectedStates(value)}
              placeholder="Select States"
              onShow={onShow}
              filter
            />
          </div>
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <Tooltip target=".custom-target-icon" />
            <label htmlFor="date">Date Range <i title="Default date range is T-30 days" className="custom-target-icon pi pi-info-circle p-text-secondary"
              data-pr-tooltip="Default date range is T-30 days"
              data-pr-position="right"
              style={{ fontSize: '0.8rem', cursor: 'pointer' }}>
            </i></label>
            <Calendar
              value={filters.dates}
              onChange={({ target: { name, value } }) => {
                // setFilters({ ...filters, [name]: value })
                setGlobalFilters(name, value)
              }
              }
              onHide={() =>
                (!filters.dates?.[0] || !filters.dates?.[1]) &&
                setGlobalFilters("dates", null)
                // setFilters({ ...filters, dates: null })
              }
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              placeholder="Select Date"
              id="dates"
              name="dates"
              showButtonBar
            />
          </div>
          <div className="d-flex flex-column gap-1 col-12 col-md-5 px-2">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={statusValue}
              onChange={(e) => setStatuesValue(e?.value)}
              options={statusList}
              optionLabel="name"
              placeholder="Status"
              optionValue="name"
              id="status"
              name="status"
            />
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-end gap-1">
          <Button label="Filter" className="filter-sign" onClick={onFilter} text>
            <i className="pi pi-filter filter-action-icon"></i>
          </Button>
          <Button label="Export" className="filter-sign" text onClick={(e) => { exportSitesData(e) }}>
            <i className="pi pi-upload export-action-icon"></i>
          </Button>
          <div className="mb-1">
              <span>Show: </span>
              <Dropdown
                value={lazyState.rows}
                options={rowsPerPageOptions}
                onChange={handleRowsPerPageChange}
                className="p-dropdown"
              />
            </div>
        </div>
      </div>
      <Dialog
        appendTo="self"
        visible={!!formData}
        modal
        header={headerElement}
        onHide={() => setFormData(null)}
      >
        <FuelSystemLinesForm
          editData={formData}
          onSave={(data) => createNewReading(data)}
          onCancel={() => setFormData(null)}
        />
      </Dialog>
      <Toast ref={toast} />
      <div>
        <DataTable
          id="fuel-lines-grid"
          ref={dt}
          dataKey="parent.id"
          showGridlines={false}
          scrollable
          loading={isLoading}
          // scrollHeight={window.innerHeight - 220}
          value={fuelMapping?.fuelList}
          size="large"
          className="fuel-system-grid rounded border flex-grow-1"
          // rows={10}
          paginator
          totalRecords={fuelMapping?.totalRecords}
          pageLinkSize={10}
          rowsPerPageOptions={rowsPerPageOptions}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
          // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          reorderableColumns
          resizableColumns
          lazy={true}
          rows={lazyState.rows}
          first={lazyState.first}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={[expandedRow]}
          onRowExpand={onRowToggle}
          onRowCollapse={onRowToggle}
          emptyMessage="No records found."
          onPage={handlePageChange}
        >
          <Column expander={allowExpansion} style={{ width: "3rem" }} />
          <Column field="parent.client_name" header="Client Name" className="text-nowrap" />
          <Column field="parent.facility_name" header="Site Name" />
          <Column field="parent.state" header="State" />
          <Column field="parent.Fule_System_Name" header="Fuel System Name" />
          <Column
            field="parent.Period_Start"
            body={(rowData) => formatDate(rowData?.parent?.Period_Start)}
            header="Period Start"
          />
          <Column
            field="parent.Period_End"
            body={(rowData) => formatDate(rowData?.parent?.Period_End)}
            header="Period End"
          />
          <Column
            field="parent.Last_Reading"
            body={(rowData) => formatDate(rowData?.parent?.Last_Reading)}
            header="Last Reading"
          />
          <Column
            field="parent.Next_Reading"
            body={(rowData) => formatDate(rowData?.parent?.Next_Reading)}
            header="Next Reading"
          />
          <Column
            field="parent.Periodic_Sales"
            body={(rowData) => formatNumber(rowData?.parent.Periodic_Sales)}
            header="Periodic Sales"
          />
          <Column
            field="parent.Periodic_Deliveries"
            body={(rowData) =>
              formatNumber(rowData?.parent.Periodic_Deliveries)
            }
            header="Periodic Deliverables"
          />
          <Column
            field="parent.Over_Under"
            body={(rowData) => formatNumber(rowData?.parent?.Over_Under)}
            header="Over/Under"
          />
          <Column field="parent.result" header="Leak Check Status" />
        </DataTable>
      </div>
    </div>
  );
}

export default FuelSystemLinesDetailTab;